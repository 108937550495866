import { css } from "@emotion/core";
const styles = {
  blackColor: css`
    color: #000;
  `,
  profilePhoto: css`
    background-image: url("/assets/profile.png");
    height: 100px;
    min-width: 100px;
    background-size: cover;
    border-radius: 50%;
    @media only screen and (max-width: 480px) {
      order: 1;
    }
  `,
  headingContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media only screen and (max-width: 480px) {
      flex-direction: column;
    }
  `,
  mainHeading: css`
    margin: 0;
    margin-right: 30px;

    span {
      font-family: "Moontime", "BlinkMacSystemFont", "Segoe UI", "Roboto",
        "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 100px;
      font-weight: 100;
    }
    @media only screen and (max-width: 480px) {
      order: 2;
      margin-right: 0;
      margin-top: 20px;
    }
  `,
  secondaryHeading: css`
    @media only screen and (max-width: 480px) {
      text-align: center;
    }
  `,
  dataSection: css`
    height: 100%;
    color: #fff;
  `,
  dataContainer: css`
    margin: 0 auto;
    padding: 0 3rem;
    max-width: 700px;
    height: 100%;
    display: flex;
    align-items: center;
  `,
  dataContent: css`
    .Typewriter {
      font-size: 1.5em;
    }
  `,
  dataTopbar: css`
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 80px;
    z-index: 1;
    p {
      float: left;
      line-height: 0px;
      font-size: 0.74rem;
      font-family: sans-serif;
      padding: 39.5px 0;
      font-weight: 700;
    }
  `,
};
export default styles;
